<template>
	<div ref="picker">
		<div v-if="wallet" class="picker-card" @click="toggleDropdown">
			<img class="wallet-left" :src="require('../../assets/dashboard-icons/wallet-left.svg')" />
			<div class="picker-left">
				<span class="name">{{ wallet.id }}</span>
				<span class="label">{{ $t('dashboard.current-balance') }}</span>
			</div>
			<div class="picker-right">
				<span>
					{{
						Intl.NumberFormat($i18n.locale, { style: 'currency', currency: wallet.currency }).format(wallet.balance)
					}}</span
				>
				<Spacer width size="s" />
				<svg
					:class="{ 'rotated-up': dropdownShow }"
					width="10"
					height="6"
					viewBox="0 0 10 6"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M-2.62268e-07 0L5 6L10 5.1656e-07" fill="white" fill-opacity="0.5" />
				</svg>
			</div>
		</div>
		<div v-if="dropdownShow" class="dropdown">
			<div v-for="w in wallets" :key="w.id" class="dropdown-item" @click="pickWallet(w.id)">
				<img class="wallet-left" :src="require('../../assets/dashboard-icons/wallet-left.svg')" />
				<div class="picker-left">
					<span class="name">{{ w.id }}</span>
					<span class="label">{{ $t('dashboard.current-balance') }}</span>
				</div>
				<div class="picker-right">
					<span>{{
						Intl.NumberFormat($i18n.locale, { style: 'currency', currency: w.currency }).format(w.balance)
					}}</span>
					<Spacer width size="s" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'WalletPicker',
	components: {
		Spacer,
	},
	props: {
		wallet: {
			type: Object,
			default: null,
		},
		wallets: {
			type: Array,
			default: null,
		},
		onWalletPicked: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			dropdownShow: false,
		};
	},
	methods: {
		toggleDropdown() {
			this.dropdownShow = !this.dropdownShow;
			if (this.dropdownShow) {
				document.addEventListener('click', this.closeDropdown, { capture: true });
			} else {
				document.removeEventListener('click', this.closeDropdown);
			}
		},
		closeDropdown(event) {
			if (this.$refs.picker) {
				if (!this.$refs.picker.contains(event.target)) {
					this.dropdownShow = false;
					document.removeEventListener('click', this.closeDropdown);
				}
			}
		},
		pickWallet(walletId) {
			this.onWalletPicked(walletId);
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.picker-card {
	@include card-highlighted;
	display: flex;
	justify-content: space-between;
	padding: $spacer-l;
	position: relative;
	cursor: pointer;
}

.wallet-left {
	position: absolute;
	width: 5px;
	height: 54px;
	left: 0px;
	top: 5px;
}

.picker-left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.picker-right {
	display: flex;
	align-items: center;
}

.name {
	font-size: $label-md;
	color: #ffffff;
}

.label {
	font-size: $label-sm;
	color: #ffffff;
	opacity: 0.5;
	text-align: left;
}

.dropdown {
	width: 100%;
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: 2;
	overflow: hidden;
	-webkit-border-bottom-right-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.dropdown-item {
	background: #353740;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	justify-content: space-between;
	padding: $spacer-l;
	position: relative;
	cursor: pointer;
}
</style>
